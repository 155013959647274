<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple bordered hover small caption-top responsive>
                  <tbody>
                    <tr>
                      <th class="text-right" style="width: 20%">{{ $t('globalTrans.fiscal_year') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td class="text-left" style="width: 20%">{{ ($i18n.locale === 'bn') ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                      <th class="text-right" style="width: 25%">{{$t('pump_install.requisition_date')}}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td class="text-left" style="width: 20%">{{ formData.requisition_date | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th class="text-right">{{ $t('pusti_mapping.month') }}</th>
                      <th class="text-center">:</th>
                      <td class="text-left">{{ ($i18n.locale === 'bn') ? formData.month_name_bn : formData.month_name }}</td>
                      <th class="text-right">{{ $t('fertilizerSales.allocationType') }}</th>
                      <th class="text-center">:</th>
                      <td class="text-left">{{ ($i18n.locale === 'bn') ? formData.allocation_type_name_bn : formData.allocation_type_name }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <b-row>
              <div class="col text-right">
                <span class="text-right text-dark font-weight-bold"> {{ $t('fertilizerReport.accountMayTons') }}</span>
              </div>
            </b-row>
            <b-table-simple class="mt-3" hover bordered small caption-top responsive>
              <b-thead head-variant="secondary">
                <b-tr>
                  <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                  <b-th class="text-center">{{ $t('fertilizerConfig.fertilizer_name') }}</b-th>
                  <b-th class="text-right">{{ $t('globalTrans.quantity') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(portInfoItem, index) in formData.requisition_details" :key="index">
                  <b-th class="text-center">{{ $n(index+1) }}</b-th>
                  <b-th class="text-center">{{ currentLocale === 'en' ? portInfoItem.fertilizer_name : portInfoItem.fertilizer_name_bn }}</b-th>
                  <b-th class="text-right">
                    {{$n(portInfoItem.quantity)}}
                  </b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { requisitionStore, requisitionUpdate } from '../../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.formData.requisition_details = []
      const fertilizerDetailsInfo = tmp.dealer_requisition_details
      this.fertilizerNameList.filter(item => {
        const fertiDetailsItem = fertilizerDetailsInfo.find(portDetailsObj => portDetailsObj.fertilizer_id === item.value)
        const portInfoItem = {
          requisition_details_id: typeof fertiDetailsItem !== 'undefined' ? fertiDetailsItem.id : 0,
          fertilizer_id: item.value,
          quantity: typeof fertiDetailsItem !== 'undefined' ? fertiDetailsItem.quantity : '',
          fertilizer_name: item.text_en,
          fertilizer_name_bn: item.text_bn
        }
        this.formData.requisition_details.push(portInfoItem)
      })
    } else {
      this.fertilizerNameList.filter(item => {
        const portInfoItem = {
          fertilizer_id: item.value,
          quantity: '',
          fertilizer_name: item.text_en,
          fertilizer_name_bn: item.text_bn
        }
        this.formData.requisition_details.push(portInfoItem)
      })
    }
  },
  mounted () {
    flatpickr('.date-picker', {})
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        fiscal_year_id: 0,
        allocation_type_id: 0,
        month: 0,
        requisition_details: []
      }
    }
  },
  computed: {
    fertilizerNameList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    allocationTypeList: function () {
        const type = this.$store.state.ExternalUserIrrigation.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
    currentLocale: function () {
      return this.$i18n.locale
    },
    fiscalYearList: function () {
      const fiscalyear = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
      return fiscalyear.map(item => {
        if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
        } else {
            return { value: item.value, text: item.text_en }
        }
      })
    },
    monthList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.monthListfiscalYear
    }
  },
  watch: {
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${requisitionUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, requisitionStore, this.formData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('SeedsFertilizer/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        if (result.errors) {
          this.$refs.form.setErrors(result.errors)
        } else {
          this.$toast.success({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
        }
      }
    }
  }
}
</script>
